import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const primaryJointAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PRIMARY_JOINT, data);
  return response;
};

export const primaryJointEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.PRIMARY_JOINT, data);
  return response;
};

export const primaryJointRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PRIMARY_JOINT + `/${id}`
  );
  return response;
};

export const primaryJoint = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.PRIMARY_JOINT}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}`
    }&limit=${data?.limit || Constants.limitCount}${
      data && data?.search ? `&search=${data?.search}` : ""
    }`
  );
  return response;
};
