import {
  exerciseGoal,
  exerciseGoalAdd,
  exerciseGoalEdit,
  exerciseGoalRemove,
} from "../../services/exerciseGoal.service";
import { types } from "./types";

export const exerciseGoalInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseGoalAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseGoalList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseGoal(data);
  if (response.status === true) {
    const result = response?.data?.data;
    dispatch({ type: types.EXERCISE_GOAL_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EXERCISE_GOAL_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseGoalUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseGoalEdit(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseGoalDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseGoalRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
