export const RoutesUrl = {
  bodyGroup: "/body-group",
  dashboard: "/dashboard",
  equipment: "/equipment",
  exerciseCategory: "/exer-category",
  exerciseGoal: "/exer-goal",
  exercisePosition: "/exer-position",
  force: "force",
  goals: "/goals",
  goalTiming: "/goal-timing",
  joint: "/joint",
  mechanicsJoint: "/mech-joint",
  movementPattern: "/move-pattern",
  muscle: "/muscle",
  pageNotFound: "/page-404",
  primaryJoint: "/primary-joint",
  primaryMuscle: "/primary-muscle",
  synergistMuscle: "/synergist-muscle",
  signIn: "/sign-in",
  users: "/users",
};
