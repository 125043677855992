import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const muscleAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.MUSCLE, data);
  return response;
};

export const muscleEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.MUSCLE, data);
  return response;
};

export const muscleRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.MUSCLE + `/${id}`);
  return response;
};

export const muscle = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.MUSCLE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}`
    }&limit=${data?.limit || Constants.limitCount}${
      data && data?.search ? `&search=${data?.search}` : ""
    }`
  );
  return response;
};
