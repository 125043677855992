import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const synergistMuscleAdd = async (data) => {
  const response = await httpServiceInstance.post(
    ApiUrl.SYNERGIST_MUSCLE,
    data
  );
  return response;
};

export const synergistMuscleEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.SYNERGIST_MUSCLE,
    data
  );
  return response;
};

export const synergistMuscleRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.SYNERGIST_MUSCLE + `/${id}`
  );
  return response;
};

export const synergistMuscle = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.SYNERGIST_MUSCLE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}`
    }&limit=${data?.limit || Constants.limitCount}${
      data && data?.search ? `&search=${data?.search}` : ""
    }`
  );
  return response;
};
