import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const equipmentAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.EQUIPMENT, data);
  return response;
};

export const equipmentEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.EQUIPMENT, data);
  return response;
};

export const equipmentRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.EQUIPMENT + `/${id}`
  );
  return response;
};

export const equipment = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.EQUIPMENT}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}`
    }&limit=${data?.limit || Constants.limitCount}${
      data && data?.search ? `&search=${data?.search}` : ""
    }`
  );
  return response;
};
