import { Constants } from "../../components/Common/Constants";
import { loginUser } from "../../services/auth.service";
import { types } from "./types";

export const login = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await loginUser(data);
  if (response.status === true) {
    const userData = response.data;
    localStorage.setItem(Constants.accessToken, userData.token);
    dispatch({ type: types.LOGIN_SUCCESS, payload: userData });
    dispatch({ type: types.LOADER_HIDE });
    return { data: userData };
  } else {
    dispatch({ type: types.LOGIN_FAIL, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
