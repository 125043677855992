import React, { useEffect, useState } from "react";
import { Constants } from "../Common/Constants";
import { RoutesUrl } from "../Common/RoutesUrl";
import Graph from "./Graph";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData] = useState([]);
  useEffect(() => {
    // Fetch dashboard data
    // dispatch(fetchDashboardData()).then((response) => {
    //   if (response?.error) {
    //     dispatch({
    //       type: types.ERROR_ALERT_VISIBLE,
    //       payload: response?.error,
    //     });
    //   } else {
    //     setDashboardData(response);
    //   }
    // });
  }, []);

  // Data to be shown in tabs
  const adminData = [
    {
      title: Constants.newSignups,
      to: RoutesUrl.users,
      value: dashboardData?.data?.total_users || 0,
      iconClass: "icofont-users fs-3 color-lightblue",
      cardBg: "bg-light",
    },
    {
      title: Constants.totalTransactions,
      to: RoutesUrl.transactions,
      value: dashboardData?.data?.total_transactions || 0,
      iconClass: "icofont-exchange fs-3 color-lavender-purple",
      cardBg: "bg-light",
    },
    {
      title: Constants.totalEnrolledUsers,
      to: RoutesUrl.users,
      value: dashboardData?.data?.total_enrolled_customer || 0,
      iconClass: "icofont-users-alt-5 fs-3 color-red",
      cardBg: "bg-light",
    },
    {
      title: Constants.totalRevenue,
      to: RoutesUrl.transactions,
      value: Constants.currency + (dashboardData?.data?.total_revenue || 0),
      iconClass: "icofont-rupee fs-3 color-green",
      cardBg: "bg-light",
    },
  ];

  return (
    <div className="container-xxl mb-4">
      <h2 className="dashboard-head">{Constants.dashboard}</h2>
      <div className="row">
        {adminData &&
          adminData.map((item, i) => {
            return (
              <div
                key={"todaydata" + i}
                className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3"
              >
                <div
                  onClick={() => (item?.to ? navigate(item.to) : "")}
                  className={`${item?.to ? "cursor-pointer" : ""}`}
                >
                  <div className="card card-m list-view">
                    <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
                      <div className="left-info col-md-10 col-8">
                        {item.title}
                        <div>
                          <span className="fs-6 fw-bold me-2">
                            {item.value}
                          </span>
                        </div>
                      </div>
                      <div className="right-icon col-md-2 col-4">
                        <i className={`${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* Revenue & Users Graph */}
      <div className="row mt-4">
        <Graph
          data={dashboardData?.data?.total_revenue_graph}
          title={Constants.revenue}
          format={Constants.currency}
        />
        <Graph
          data={dashboardData?.data?.total_users_graph}
          title={Constants.users}
          format=""
        />
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default Dashboard;
