export const Constants = {
  accessToken: "accessToken",
  actions: "Actions",
  add: "Add",
  addBodyGroup: "Add Body Group",
  addEquipment: "Add Equipment",
  addExercisePosition: "Add Exercise Position",
  addExerciseCategory: "Add Exercise Category",
  addExerciseGoal: "Add Exercise Goal",
  addForce: "Add Force",
  addGoal: "Add Goal",
  addJoint: "Add Avoid for Joint",
  addMechanicsJoint: "Add Joint",
  addMovementPattern: "Add Movement Pattern",
  addMuscle: "Add Avoid for Muscle",
  addPrimaryJoint: "Add Primary Joint",
  addPrimaryMuscle: "Add Primary Muscle",
  addSynergistMuscle: "Add Synergist Muscle",
  admin: "Admin",
  areYouSure: "Are you sure?",
  bodyGroup: "Body Group",
  bodyGroupKey: "body-group",
  bodyGroupList: "Body Group List",
  bodyParts: "Body Parts",
  cms: "CMS",
  createdDate: "Created Date",
  createdTime: "Created Time",
  customer: "Customers",
  customerList: "Customer List",
  dashboard: "Dashboard",
  dashboardKey: "dashboard",
  dateFormat: "D MMM YYYY",
  delete: "Delete",
  deleted: "Deleted",
  dob: "Date of birth",
  edit: "Edit",
  editBodyGroup: "Edit Body Group",
  editEquipment: "Edit Equipment",
  editExerciseCategory: "Edit Exercise Category",
  editExerciseGoal: "Edit Exercise Goal",
  editExercisePosition: "Edit Exercise Position",
  editForce: "Edit Force",
  editGoal: "Edit Goal",
  editJoint: "Edit Avoid for Joint",
  editMechanicsJoint: "Edit Joint",
  editMovementPattern: "Edit Movement Pattern",
  editMuscle: "Edit Avoid for Muscle",
  editPrimaryJoint: "Edit Primary Joint",
  editPrimaryMuscle: "Edit Primary Muscle",
  editSynergistMuscle: "Edit Synergist Muscle",
  email: "Email",
  equipment: "Equipment",
  equipmentKey: "equipment",
  equipmentList: "Equipment List",
  exerciseCategory: "Exercise Category",
  exerciseCategoryKey: "exer-category",
  exerciseCategoryList: "Exercise Category List",
  exerciseGoal: "Exercise Goal",
  exerciseGoalKey: "exer-goal",
  exerciseGoalList: "Exercise Goal List",
  exercisePosition: "Exercise Position",
  exercisePositionKey: "exer-position",
  exercisePositionList: "Exercise Position List",
  export: "EXPORT",
  force: "Force",
  forceKey: "force",
  forceList: "Force List",
  goalKey: "goals",
  goalList: "Goal List",
  goals: "Goals",
  goalTiming: "Goal TIming",
  goalTimingKey: "goal-timing",
  goalTimingType: [
    {
      value: "WEEKS",
      label: "Weeks",
    },
    {
      value: "MONTHS",
      label: "Months",
    },
    {
      value: "YEARS",
      label: "Years",
    },
  ],
  gender: "Gender",
  height: "Height",
  heightTypeCm: "CMS",
  heightTypeFt: "FEET",
  id: "ID",
  invalidEmail: "Invalid Email",
  itemDeleted: "Item has been deleted",
  joint: "Avoid for Joint",
  jointKey: "joint",
  jointList: "Joint List",
  limitCount: 10,
  login: "Login",
  mechanics: "Mechanics",
  mechanicsJoint: "Joint",
  mechanicsJointKey: "mech-joint",
  mechanicsJointList: "Joint List",
  movementPattern: "Movement Pattern",
  movementPatternKey: "move-pattern",
  movementPatternList: "Movement Pattern List",
  muscle: "Avoid for Muscle",
  muscleKey: "muscle",
  muscleList: "Muscle List",
  na: "N/A",
  name: "Name",
  newSignups: "New Signups",
  noRevert: "You won't be able to revert this!",
  oops: "Oops!",
  password: "Password",
  passwordRequired: "Password must be 7 characters at minimum",
  passwordValidation:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  required: "This field cannot be empty",
  primaryJoint: "Primary Joint",
  primaryJointKey: "primary-joint",
  primaryJointList: "Primary Joint List",
  primaryMuscle: "Primary Muscle",
  primaryMuscleKey: "primary-muscle",
  primaryMuscleList: "Primary Muscle List",
  revenue: "Revenue",
  search: "Search",
  searchByEmail: "Search by Email, Username",
  searchByName: "Search by Name",
  searchByTitle: "Search by Title",
  signout: "Sign out",
  submit: "Submit",
  success: "Success",
  synergistMuscle: "Synergist Muscle",
  synergistMuscleKey: "synergist-muscle",
  synergistMuscleList: "Synergist Muscle List",
  timeFormat: "hh:mm A",
  title: "Title",
  totalEnrolledUsers: "Total Enrolled Users",
  totalRevenue: "Total Revenue",
  totalTransactions: "Total Transactions",
  type: "Type",
  unit: "Unit",
  users: "Users",
  usersKey: "users",
  validEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  validPasswordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%#*?&]{7,}$/,
  weight: "Weight",
  weightTypeKgs: "KGS",
  weightTypeLbs: "LBS",
  welcome: "Welcome",
  youWantToSignOut: "You want to sign out?",
};
