import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const jointAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.JOINT, data);
  return response;
};

export const jointEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.JOINT, data);
  return response;
};

export const jointRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.JOINT + `/${id}`);
  return response;
};

export const joint = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.JOINT}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}`
    }&limit=${data?.limit || Constants.limitCount}${
      data && data?.search ? `&search=${data?.search}` : ""
    }`
  );
  return response;
};
