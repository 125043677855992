import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../helper/HttpService";

export const goalTimingAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.GOAL_TIMING, data);
  return response;
};

export const goalTimingEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.GOAL_TIMING, data);
  return response;
};

export const goalTiming = async (data) => {
  const response = await httpServiceInstance.get(`${ApiUrl.GOAL_TIMING}`);
  return response;
};
