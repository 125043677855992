import { combineReducers } from "redux";
import AlertMessage from "./AlertMessage";
import Auth from "./Auth";
import BodyGroup from "./BodyParts/BodyGroup";
import ExerciseGoal from "./ExerciseGoal";
import ExercisePosition from "./BodyParts/ExercisePosition";
import Joint from "./BodyParts/Joint";
import Muscle from "./BodyParts/Muscle";
import PrimaryJoint from "./BodyParts/PrimaryJoint";
import PrimaryMuscle from "./BodyParts/PrimaryMuscle";
import SynergistMuscle from "./BodyParts/SynergistMuscle";
import Equipment from "./Equipment";
import ExerciseCategory from "./ExerciseCategory";
import Goals from "./Goals";
import Loader from "./Loader";
import Users from "./Users";

export default combineReducers({
  AlertMessage,
  Auth,
  BodyGroup,
  Equipment,
  ExerciseCategory,
  ExerciseGoal,
  ExercisePosition,
  Goals,
  Joint,
  Loader,
  Muscle,
  PrimaryJoint,
  PrimaryMuscle,
  SynergistMuscle,
  Users,
});
