import { Constants } from "./Constants";

// Sidebar menu when user is logged in
export const Menu = [
  {
    name: Constants.dashboard,
    routerLink: [Constants.dashboardKey],
    iconClass: "icofont-home fs-5",
    children: [],
  },
  {
    name: Constants.bodyParts,
    routerLink: [""],
    iconClass: "icofont-gym-alt-1 fs-5",
    children: [
      {
        name: Constants.bodyGroup,
        routerLink: [Constants.bodyGroupKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.exercisePosition,
        routerLink: [Constants.exercisePositionKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.joint,
        routerLink: [Constants.jointKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.muscle,
        routerLink: [Constants.muscleKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.primaryJoint,
        routerLink: [Constants.primaryJointKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.primaryMuscle,
        routerLink: [Constants.primaryMuscleKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.synergistMuscle,
        routerLink: [Constants.synergistMuscleKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.customer,
    routerLink: [Constants.usersKey],
    iconClass: "icofont-users-alt-3 fs-5",
    children: [],
  },
  {
    name: Constants.cms,
    routerLink: [""],
    iconClass: "icofont-ui-settings fs-5",
    children: [
      {
        name: Constants.goalTiming,
        routerLink: [Constants.goalTimingKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.equipment,
    routerLink: [Constants.equipmentKey],
    iconClass: "icofont-dumbbell fs-5",
    children: [],
  },
  {
    name: Constants.exerciseCategory,
    routerLink: [Constants.exerciseCategoryKey],
    iconClass: "icofont-cube fs-5",
    children: [],
  },
  {
    name: Constants.exerciseGoal,
    routerLink: [Constants.exerciseGoalKey],
    iconClass: "fa fa-star-o fs-5",
    children: [],
  },
  {
    name: Constants.goals,
    routerLink: [Constants.goalKey],
    iconClass: "icofont-goal fs-5",
    children: [],
  },
  // {
  //   name: Constants.mechanics,
  //   routerLink: [""],
  //   iconClass: "icofont-help-robot fs-5",
  //   children: [
  //     {
  //       name: Constants.mechanicsJoint,
  //       routerLink: [Constants.mechanicsJointKey],
  //       iconClass: "",
  //       children: [],
  //     },
  //     {
  //       name: Constants.movementPattern,
  //       routerLink: [Constants.movementPatternKey],
  //       iconClass: "",
  //       children: [],
  //     },
  //     {
  //       name: Constants.force,
  //       routerLink: [Constants.forceKey],
  //       iconClass: "",
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   name: Constants.signout,
  //   routerLink: [Constants.signout],
  //   iconClass: "icofont-sign-out fs-5",
  //   children: [],
  // },
];
