import { Routes, Route, Navigate } from "react-router-dom";
import "./assets/scss/main.scss";
import Page404 from "./components/Auth/Page404";
import Header from "./components/Common/Header";
import { RoutesUrl } from "./components/Common/RoutesUrl";
import SignIn from "./components/Auth/SignIn";
import UsersList from "./components/Users";
import Dashboard from "./components/Dashboard/Dashboard.js";
import React from "react";
import GoalList from "./components/Goals/index.js";
import EquipmentList from "./components/Equipment/index.js";
import ExerciseCategoryList from "./components/ExerciseCategory/index.js";
import MuscleList from "./components/BodyParts/Muscle/index.js";
import JointList from "./components/BodyParts/Joint/index.js";
import BodyGroupList from "./components/BodyParts/BodyGroup/index.js";
import PrimaryJointList from "./components/BodyParts/PrimaryJoint/index.js";
import PrimaryMuscleList from "./components/BodyParts/PrimaryMuscle/index.js";
import SynergistMuscleList from "./components/BodyParts/SynergistMuscle/index.js";
import ExercisePositionList from "./components/BodyParts/ExercisePosition/index.js";
import ExerciseGoalList from "./components/ExerciseGoal/index.js";
import GoalTimingForm from "./components/CMS/GoalTimingForm.js";

const MainRoutes = (props) => {
  const { activekey } = props;

  return (
    <div
      className={`main space-side header-bg ${
        activekey === RoutesUrl.signIn ? "sign-in-bg" : ""
      }`}
    >
      {activekey === RoutesUrl.pageNotFound ||
      activekey === RoutesUrl.signIn ? (
        ""
      ) : (
        <Header />
      )}
      <div className="body d-flex">
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path={RoutesUrl.bodyGroup} element={<BodyGroupList />} />
          <Route path={RoutesUrl.dashboard} element={<Dashboard />} />
          <Route path={RoutesUrl.equipment} element={<EquipmentList />} />
          <Route
            path={RoutesUrl.exerciseCategory}
            element={<ExerciseCategoryList />}
          />
          <Route path={RoutesUrl.exerciseGoal} element={<ExerciseGoalList />} />
          <Route
            path={RoutesUrl.exercisePosition}
            element={<ExercisePositionList />}
          />
          <Route path={RoutesUrl.goals} element={<GoalList />} />
          <Route path={RoutesUrl.goalTiming} element={<GoalTimingForm />} />
          <Route path={RoutesUrl.joint} element={<JointList />} />
          <Route path={RoutesUrl.muscle} element={<MuscleList />} />
          <Route path={RoutesUrl.pageNotFound} element={<Page404 />} />
          <Route path={RoutesUrl.primaryJoint} element={<PrimaryJointList />} />
          <Route
            path={RoutesUrl.primaryMuscle}
            element={<PrimaryMuscleList />}
          />
          <Route
            path={RoutesUrl.synergistMuscle}
            element={<SynergistMuscleList />}
          />
          <Route path={RoutesUrl.signIn} element={<SignIn />} />
          <Route path={RoutesUrl.users} element={<UsersList />} />
          <Route
            path="*"
            element={<Navigate replace to={RoutesUrl.pageNotFound} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default MainRoutes;
