export const ApiUrl = {
  BODY_GROUP: "/admin/master/BodyGroups",
  EQUIPMENT: "admin/master/Equipment",
  EXERCISE_CATEGORY: "admin/exercise/ExerciseCategory",
  EXERCISE_GOAL: "admin/master/ExerciseGoal",
  EXERCISE_POSITION: "admin/master/ExercisePosition",
  FORCE: "admin/master/Force",
  GOAL: "admin/goal",
  GOAL_TIMING: "admin/master/goalTiming",
  JOINT: "admin/master/AvoidForJoints",
  LOGIN: "auth/register",
  MECHANICS_JOINT: "admin/master/Mechanics",
  MOVEMENT_PATTERN: "admin/master/MovementPatterns",
  MUSCLE: "admin/master/AvoidForMuscle",
  PRIMARY_JOINT: "admin/master/PrimaryJoints",
  PRIMARY_MUSCLE: "admin/master/Primarymuscles",
  SYNERGIST_MUSCLE: "admin/master/Synergistmuscle",
  USER_LIST: "admin/user/list",
};
