import {
  equipment,
  equipmentAdd,
  equipmentEdit,
  equipmentRemove,
} from "../../services/equipment.service";
import { types } from "./types";

export const equipmentInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await equipmentAdd(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const equipmentList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await equipment(data);
  if (response.status === true) {
    const result = response?.data?.data;
    dispatch({ type: types.EQUIPMENT_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EQUIPMENT_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const equipmentUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await equipmentEdit(data);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const equipmentDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await equipmentRemove(id);
  if (response.status === true) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
